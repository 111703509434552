export const setidTipoIdentificacion = (idTipoIdentificacion) => {
    console.log('typeIdentification__:', idTipoIdentificacion)
    localStorage.setItem('idTipoIdentificacion', idTipoIdentificacion)
}

export const setidTipoFactura = (idTipoFactura) => {
    console.log('idTipoFactura__:', idTipoFactura)
    localStorage.setItem('idTipoFactura', idTipoFactura)
}


import React, { useEffect, useState } from 'react';
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    CircularProgress,
    Alert

} from '@mui/material';
import './style.css'; // Import the CSS file
import { endPointAPiBoleraApp } from '../api/api-request'
import HeaderLogoBoliche from '../header';
import { useNavigate } from 'react-router-dom';
import DisplayReserva from './DisplayReserva';
import { getConsultReserve } from '../api/api-request';

const TiloPayForm = () => {
    const [formData, setFormData] = useState({
        primerApellido: '',
        email: '',
        codigoConfirmacion: ''

    });

    const [openPopup, setOpenPopup] = useState(false);
    const [warningMsg, setWarningMsg] = useState(false);
    const [openWarningPopup, setWarningPopup] = useState(false);
    const [getMsgConsult, setMsgConsult] = useState('')
    const [loader, setLoader] = useState(false)
    const [getStateConsult, setStateConsult] = useState(false)
    //=== INFO Promo Rent 
    const [dataPromoRent, setDataPromoRent] = useState([])
    //== DATA Reserved
    const [getDataRsvd, setDataRsvd] = useState([])
    const [getDataContacto, setDataContacto] = useState([])
    //== Show Reserve Card
    const [getInfoDataRsv, setInfoDataRsv] = useState(false)
    //== get Id Reserve
    const [getIdReserved, setGetIdReserve] = useState('')


    //=Navigate to Validate PAY
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can perform your form submission logic here

        // Reset the form
        // setFormData({
        //     apellido: '',
        //     email: '',
        //     codigoConfirm: ''

        // });
        // // Perform form validation

        handleOpenPopup()

        if (!isFormEmpty) {
            //Open Warning Window
            openWarningPopupWin()
        }

    };

    const handleOpenPopup = () => {
        setOpenPopup(true)
        setWarningPopup(false)
        setWarningMsg(true)
        //Loader setup
        setLoader(true)
        //Send Post for get validation consult
        //getConsultReserve(formData);
        handleConsultation(formData)
    }

    const handleClosePopup = () => {
        setOpenPopup(false)
    }

    const gotoModifyReserva = () => {
        navigate('/modificar')

    }

    const openWarningPopupWin = () => {
        setWarningPopup(true)
    }

    const WinTiloPayPOPUP = ({ message, stateValidate, getDataRsvd }) => {
        return (
            <Dialog open={openPopup} maxWidth="md" fullWidth>
                {
                    !loader
                        ?
                        <>
                            <DialogContent>
                                {

                                    stateValidate
                                        ?
                                        <div>
                                            <Alert severity="success">{message}</Alert>
                                            <DisplayReserva
                                                name={getDataRsvd.reservaContacto.nombreCompleto}
                                                email={getDataRsvd.reservaContacto.email}
                                                phone={getDataRsvd.reservaContacto.telefono}
                                                nameSede={getDataRsvd.reservaProductoAlquiler.sedeNombre}
                                                date={getDataRsvd.reservaProductoAlquiler.fecha}
                                                hour={getDataRsvd.reservaProductoAlquiler.horaDiaDescripcion}
                                                persons={getDataRsvd.reservaProductoAlquiler.cantidadPersonas}
                                                codigoReserva={getDataRsvd.reservaConfirmacion.codigoConfirmacion}
                                                description={getDataRsvd.reservaProductoAlquiler.descripcion}
                                                cantidadMaximaPersonas={getDataRsvd.reservaProductoAlquiler.cantidadPersonas}
                                                subTotal={getDataRsvd.subTotal}
                                                totalComisiones={getDataRsvd.totalComisiones}
                                                precio={getDataRsvd.total}
                                            />
                                        </div>

                                        :
                                        <Alert severity="error">{message}</Alert>
                                }
                                {stateValidate ?
                                <button onClick={gotoModifyReserva} className="btnSend">
                                    Modificar
                                </button>
                                :
                                <></>
                                }
                            </DialogContent>
                            <DialogActions>
                                <button onClick={handleClosePopup} className="btnSend">
                                    Regresar
                                </button>
                            </DialogActions>
                        </>
                        : <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <CircularProgress style={{ color: 'purple' }} />
                            <Typography >
                                Cargando...
                            </Typography>

                        </div>
                }
            </Dialog>
        )
    }


    //evaluate if form is empty
    const isFormEmpty = ['codigoConfirmacion', 'primerApellido'].some(field => String(formData[field]).trim() === '');

    //Connection to API
    const urlTiloPayEndPoint = endPointAPiBoleraApp + '/api/v2/External/GetDetailsByConfirmacion'

    const getConsultReserve = async (data = {}) => {

        try {
            const response = await fetch(urlTiloPayEndPoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Error occurred during the request.');
            }

            const responseData = await response.json();
            setMsgConsult(responseData.message)
            setStateConsult(responseData.isCorrect && responseData.data != null)
            // Use the getData
            //console.warn('Get Data rsv   consultar', responseData);
            if (responseData.isCorrect && responseData.data != null) {
                setDataRsvd(responseData.data)
                setDataContacto(responseData.data.reservaContacto)
                setInfoDataRsv(true)

                localStorage.setItem('consultarReserva', JSON.stringify(data))
            }

            setTimeout(() => { setLoader(false) }, 2500)

        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    const handleReserveSaved = (reserveId) => {
        // Handle saved reserve
        console.log('Reserve saved:', reserveId);
    };

    const handleConsultation = async (data) => {
        setLoader(true);
        try {
            await getConsultReserve(
                data,
                setMsgConsult,
                setStateConsult,
                setLoader,
                handleReserveSaved
            );
        } catch (error) {
            // Handle error
            console.error('Consultation failed:', error);
        }
    };

    

    useEffect(() => {
        document.body.style.backgroundColor = "#f0f0f0";
    }, [])


    return (
        <div>
            <HeaderLogoBoliche />

            {
                warningMsg
                    ? <WinTiloPayPOPUP
                        message={getMsgConsult}
                        stateValidate={getStateConsult}
                        getDataRsvd={getDataRsvd} />
                    : null
            }

            <div className="form-container" style={{ marginTop: 20 }}>

                <div className="form">
                    <Typography variant='h5'>
                        Consultar reserva
                    </Typography>
                    <TextField
                        name="primerApellido"
                        label="Apellido"
                        value={formData.primerApellido}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        error={formData.primerApellido === '' ? true : false}
                    />
                    <TextField
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    // required
                    // error={formData.email === '' ? true : false}
                    />
                    <TextField
                        name="codigoConfirmacion"
                        label="Código de confirmación"
                        value={formData.codigoConfirmacion}
                        onChange={handleChange}
                        style={{ textTransform: 'uppercase' }}
                        fullWidth
                        margin="normal"
                        required
                        error={formData.codigoConfirmacion === '' ? true : false}
                    />
                    <button
                        variant="contained"
                        className="btnSend"
                        onClick={handleSubmit}
                        disabled={isFormEmpty}
                    >
                        Consultar
                    </button>

                    {/* <button
                        variant="contained"
                        className="btnSend"
                        onClick={() => navigate('/modificar')}

                    >
                        Modificar Reserva
                    </button> */}


                </div>



            </div>
        </div>

    );
};

export default TiloPayForm;
import React from 'react';
import {
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    CircularProgress,
    Alert,
    Divider

} from '@mui/material';

import DisplayReserva from './DisplayReserva';



const WinTiloPayPOPUP = ({ message, stateValidate, getDataRsvd, openPopup, loader, handleClosePopup }) => {
    return (
        <Dialog open={openPopup} maxWidth="md" fullWidth>
            {
                !loader
                    ?
                    <>
                        <DialogContent>
                            {

                                stateValidate
                                    ?
                                    <div>
                                        <Alert severity="success">{message}</Alert>
                                        <DisplayReserva
                                            name={getDataRsvd.reservaContacto.nombreCompleto}
                                            email={getDataRsvd.reservaContacto.email}
                                            phone={getDataRsvd.reservaContacto.telefono}
                                            nameSede={getDataRsvd.reservaProductoAlquiler.sedeNombre}
                                            date={getDataRsvd.reservaProductoAlquiler.fecha}
                                            hour={getDataRsvd.reservaProductoAlquiler.horaDiaDescripcion}
                                            persons={getDataRsvd.reservaProductoAlquiler.cantidadPersonas}
                                            codigoReserva={getDataRsvd.reservaConfirmacion.codigoConfirmacion}
                                            description={getDataRsvd.reservaProductoAlquiler.descripcion}
                                            cantidadMaximaPersonas={getDataRsvd.reservaProductoAlquiler.cantidadPersonas}
                                            subTotal={getDataRsvd.subTotal}
                                            totalComisiones={getDataRsvd.totalComisiones}
                                            precio={getDataRsvd.total}
                                        />
                                    </div>

                                    :
                                    <Alert severity="error">{message}</Alert>
                            }
                            <button onClick={handleClosePopup} className="btnSend">
                                Modificar
                            </button>
                        </DialogContent>
                        <DialogActions>
                            <button onClick={handleClosePopup} className="btnSend">
                                Regresar
                            </button>
                        </DialogActions>
                    </>
                    : <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <CircularProgress style={{ color: 'purple' }} />
                        <Typography >
                            Cargando...
                        </Typography>

                    </div>
            }
        </Dialog>
    )
}


export default WinTiloPayPOPUP
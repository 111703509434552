import React, { useState, useEffect, useContext } from "react";
import { getSedeId, getDataProducts, saveReserveData, getHoursBySede, getListCountry } from "../api/api-request";
import DisplayReserva from "./DataReserva";
import GetProductsRent from "./GetProductsRent";
import CheckReserve from "../modal";
import HeaderLogoBoliche from "../header";
import './style.css'
import { CircularProgress } from "@material-ui/core";
import { DataContext } from "../../store/DataContext";
import { Alert, AlertTitle } from '@mui/material';
import Apple from '../../images/Apple.png'
import Google from '../../images/GooglePlay.png'
import TiposDeFactura from "../TipoDeFactura";
import {
    Box
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { StyledTextField, formControlStyles, alignLeft } from "../TipoDeFactura/StyleToTextField";
import CountrySelect from "../CountrySelect";
import { boxStyles } from "./FormStyle";

const MultiStepForm = () => {
    const [step, setStep] = useState(1);
    const [submit, setSubmit] = useState(false)
    const [getRangoHorasSede, setRangoHoras] = useState([])
    const [formData, setFormData] = useState({
        fecha: "",
        hora: "",
        cantidad: "",
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        name: "",
        password: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        descriptPromoRent: ""

    });


    //DataProvider
    const { updateFormData, data, setData, idRegularExpression, optionForm, minLengthCharsId } = useContext(DataContext);

    const [createRsv, setCreateRsv] = useState([])

    const [preloadData, setPreloadData] = useState(false)
    const [selectedHour, setSelectedHour] = useState('');
    const [btnStep, setBtnStep] = useState(true);

    //Error Messages
    const [message, setMessage] = useState('')
    //Get Sede
    const [getSedeNameBol, setSedeNameBol] = useState('')
    //Emails are matching
    const [email2, setEmail2] = useState('');
    //Save number of sede
    const [getSedeIdNum, setSedeIdNum] = useState("");
    //Show Menu
    const [getShowMenu, setShowMenu] = useState(false)
    //Show Hour Menu
    const [getHourSelect, setHourSelect] = useState("")
    //show Cantidad Personas
    const [count, setCount] = useState(0);
    const [selectedNumber, setSelectedNumber] = React.useState(0);
    //Save list country
    const [getCountryList, setCountry] = useState([])

    const [getPackages, setPackages] = useState(null)

    const [showModal, setShowModal] = useState(false);
    // saved tipo de factura
    const [tipoFactura, setTipoFactura] = useState(0)
    //load styles
    const theme = useTheme();


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Add validation for 'identificacion'
        if (name === 'identificacion') {
            // Allow only numeric values and restrict to 9 characters
            const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            const validatedValue = numericValue.slice(0, 9); // Restrict to 9 digits

            setFormData((prevState) => ({
                ...prevState,
                [name]: validatedValue
            }));

            updateFormData(name, validatedValue);
            return;
        }

        // General update logic for other fields
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        updateFormData(name, value);

        if (name === 'fecha') {
            localStorage.setItem('fecha', value);
            setCreateRsv((prevState) => ({
                ...prevState,
                [name]: value
            }));


            const getHoursBySedeBolera = async () => {
                try {
                    const getHours = await getHoursBySede(localStorage.getItem('fecha'), localStorage.getItem('sedeID'));
                    setRangoHoras(getHours.data)
                    //console.log('hora', getHours)
                    return getRangoHorasSede
                } catch (error) {
                    console.error(error.message || 'Failed to fetch countries.');
                }
            };

            getHoursBySedeBolera();

            //console.log('getRangoHorasSede:', getRangoHorasSede)

        }

        if (name === 'cantidad') {
            localStorage.setItem('cantidad', value);
            setCreateRsv((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const fetchProductsAlquiler = async () => {
        try {
            // Check if localStorage exists in the environment
            if (typeof window === 'undefined' || !window.localStorage) {
                console.warn('localStorage is not available in this environment');
                return null;
            }

            // Get stored data with a default empty object if not exists
            const storedData = localStorage.getItem('descriptPromoRent');
            const parsedData = storedData ? JSON.parse(storedData) : null;

            // Only fetch new data if we don't have valid stored data
            if (!parsedData) {
                const productsResponse = await getDataProducts();

                if (!productsResponse?.data) {
                    throw new Error('Invalid response format from getDataProducts');
                }

                // Update state
                setPackages(productsResponse.data);

                // Store the fresh data
                try {
                    localStorage.setItem('descriptPromoRent', JSON.stringify(productsResponse.data));
                } catch (storageError) {
                    console.warn('Failed to store data in localStorage:', storageError);
                    // Continue execution even if storage fails
                }

                return getPackages;
            }

            // If we have valid stored data, use it
            setPackages(parsedData);
            return getPackages;

        } catch (error) {
            console.error('Error in fetchProductsAlquiler:', error);
            // You might want to set some error state here
            console.error(error.message || 'Failed to fetch products');
            return null;
        }
    };

    const handleNextStep = () => {

        setStep(step + 1);
        //console.log('step', step)
        if (step == 1) {
            //Get Rent Data OPtions
            const dataObjAlquiler = {
                "idSede": localStorage.getItem('sedeID'),
                "idHoraDia": localStorage.getItem('idHoraDia'),
                "fecha": localStorage.getItem('fecha'),
                "cantidadPersonas": localStorage.getItem('cantidad')
            }

            localStorage.setItem('rsv', JSON.stringify(dataObjAlquiler))
            //getDataProducts()
            fetchProductsAlquiler();
            setPreloadData(true)


        }
    };

    useEffect(() => {
        //Evaluate 1st step if we have data from endpoint data rent
        setTimeout(() => {
            const objectRsv = JSON.parse(localStorage.getItem('descriptPromoRent'))
            const isDataRsv = localStorage.getItem('isCorrect')
            //Evaluate if data promo rent is null
            if (objectRsv === null && isDataRsv) {
                //console.log('EmptyOIbject')
                setPreloadData(false)
                // setStep(1)
                let getMessageApi = JSON.parse(localStorage.getItem('msgFromAPi'))
                //console.log('getMessageFromApi', getMessageApi)
                setMessage(getMessageApi)
            } else {
                setPreloadData(false)
            }

        }, 1500)

    }, [step == 2])

    //Validate all fields Fecha | Hora | Cantidad 

    useEffect(() => {

        const evalRsvObject = () => {

            if (!createRsv.fecha || !createRsv.hora || !createRsv.cantidad) {
                return true
            } else {
                return false
            }
        }

        setBtnStep(evalRsvObject())
    }, [createRsv])


    useEffect(() => {
        document.body.style.backgroundColor = "#f0f0f0";
    }, [])

    //Evaluate for get products rent
    const LoadingScreen = () => {
        return (
            <div className="loadingPage">
                <CircularProgress />
            </div>
        )
    }

    const handlePrevStep = () => {
        setStep(step - 1);
        console.log(' < BACK', step)
    };

    const handleSubmit = (event) => {

        event.preventDefault();
        saveReserveData()

        setTimeout(() => {
            if (JSON.parse(localStorage.getItem('rsvSaved')) !== null) {
                setSubmit(true)
            }

        }, 1500)
        //console.log('DataSaved:', JSON.parse(localStorage.getItem('rsvSaved')))

    };


    const getSedeName = () => {

        const queryString = window.location.pathname;
        const str = queryString
        const match = str.match(/\d+/);
        const num = match ? parseInt(match[0]) : null;

        localStorage.setItem('sedeID', num)

        getSedeId(num).then((data) => setSedeNameBol(data.data.nombre))
        setSedeIdNum(num)
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // console.log('formData.pais', e.target.value)
    };

    useEffect(() => {
        getSedeName()
        const fetchCountries = async () => {
            try {
                const countryList = await getListCountry();
                setCountry(countryList.data);
                return getCountryList
            } catch (error) {
                console.error(error.message || 'Failed to fetch countries.');
            }
        };

        fetchCountries();

    }, [])

    useEffect(() => {
        // console.log('getRENT ID:::::', localStorage.getItem('idProductoAlquiler'))
        // console.log('List Countries: ', getCountryList)
    }, [localStorage.getItem('idProductoAlquiler')])

    //componet select hora

    const SelectHora = () => {
        return (
            <select
                id="hour-select"
                name="hora"
                style={{ width: '95%', height: 37, marginTop: 2 }}
                value={selectedHour} // Use value here
                onChange={handleHourChange}
            >
                <option value="" disabled>
                    Seleccione hora
                </option>
                {getRangoHorasSede.map(hour => (
                    <option key={hour.idHoraDia} value={hour.descripcion}>
                        {hour.descripcion}
                    </option>
                ))}
            </select>

        )

    }


    const handleHourChange = (event) => {
        const selectedHour = event.target.value;

        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        localStorage.setItem('horaReservada', selectedHour)
        ////console.log("Selected hour:", selectedHour);
        const filterIdHoraDia = getRangoHorasSede.filter(hour => hour.descripcion === event.target.value)
        ////console.log('filterIdHoraDia', filterIdHoraDia)
        const getIdHoraDia = filterIdHoraDia[0].idHoraDia
        ////console.log('formattedHour', selectedHour)
        setSelectedHour(selectedHour)

        //console.log('getIdHoraDia', getIdHoraDia)
        localStorage.setItem('idHoraDia', getIdHoraDia)

        ////console.log('Data Hour', formData)
        setCreateRsv((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const QuantPeopleSelect = () => {

        const handleChange = (event) => {

            const { name, value } = event.target;
            setSelectedNumber(value);
            setCreateRsv((prevState) => ({
                ...prevState,
                'cantidad': value
            }));

            localStorage.setItem('cantidad', event.target.value)
            //console.log('selected:', selectedNumber)
            //console.log('CANTIDAD::::', value, "createRsv", createRsv, "NAME", name)

        };

        return (
            <select value={selectedNumber} onChange={handleChange} name="cantidad" className="cantidadPersonas">
                <option value="">Seleccione cantidad:</option>
                {Array.from({ length: 15 }, (_, i) => i + 1).map((value) => (
                    <option
                        key={value}
                        placeholder="Cantidad de Personas"
                        value={value}>
                        {value}
                    </option>
                ))}
            </select>
        );
    };


    const renderStepOne = () => {
        setData(0)
        localStorage.setItem('idProductoAlquiler', 0);
        //console.log('status Data:', data)


        const toggleModal = () => {
            setShowModal(false);
        };

        const loadModal = () => {
            setShowModal(true);
        };

        setTimeout(loadModal, 1500)

        const alertMessage = "Para una mejor visualización y rendimiento desde el celular por favor utilizar el app de Android o iOS."

        const getToday = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>


                {
                    //showModal ? <div>Using mobile Phone</div> : null
                    showModal
                        ?
                        <div className="showAlertMessage">
                            <Alert severity="info">
                                {alertMessage}
                            </Alert>
                        </div> : null


                }

                <div className="container">
                    <h2>Datos de la fecha y hora</h2>
                    <h3>Sede:{getSedeNameBol}</h3>

                    <div className="input-container">
                        <div className="contInputs">
                            <label><span>Fecha:</span></label>
                            <input
                                value={formData.fecha}
                                type="date"
                                id="fecha"
                                name="fecha"
                                onChange={handleInputChange}
                                placeholder="Fecha"
                                min={getToday()} // This disables all dates before today
                            />
                        </div>
                        <div className="contInputHour">
                            {/* <label><span>Hora:{' '}{selectedHour}</span></label> */}
                            <label><span>Hora:{' '}</span></label>
                            {
                                getRangoHorasSede !== null ? <SelectHora /> : null
                                //getRangoHorasSede !== null ? <TimePickerComponent dataHours={getRangoHorasSede} /> : null

                            }
                        </div>
                        <div className="contInputs">
                            <label><span>Cantidad de Personas:</span></label>
                            <QuantPeopleSelect />
                        </div>
                    </div>


                    <div>
                        <button
                            onClick={handleNextStep}
                            disabled={btnStep}
                            className={btnStep ? 'btnStepDisable' : 'btnStepEnable'}
                        >Siguiente</button>

                    </div>
                    <div id='logosApp'>
                        <div>
                            <a href="https://play.google.com/store/apps/details?id=com.consultyx.bolcr" target="_blank">
                                <img src={Google} />
                            </a>
                        </div>
                        <div>
                            <a href="https://apps.apple.com/us/app/bolcr/id6448749091" target="_blank">
                                <img src={Apple} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    //llenar reserva
    const dataCheckReserva = JSON.parse(localStorage.getItem('rsv'))
    const descriptPromoRent = JSON.parse(localStorage.getItem('descriptPromoRent'))

    const RenderRentPromos = () => {
        return <GetProductsRent />
    }


    const RenderStepTwo = () => {


        const DisplayListPromoRSV = () => {
            return (
                <div className="grid-container">
                    <RenderRentPromos />
                </div>
            )
        }

        const DisplayErrMsg = () => {
            return (
                <><h3>{message}</h3></>
            )
        }

        return (
            <div>
                <h2 style={{ fontWeight: 'bolder' }}>Datos de la Reserva</h2>


                <DisplayReserva
                    date={dataCheckReserva.fecha}
                    nameSede={localStorage.getItem('sede')}
                    hours={localStorage.getItem('horaReservada')}
                    persons={dataCheckReserva.cantidadPersonas} />

                {
                    getPackages === null
                        ? <DisplayErrMsg />
                        : <DisplayListPromoRSV />

                }


                <br />
                <div className="containerNavBtn">
                    <button onClick={handlePrevStep}>Atrás</button>
                    {
                        data !== 0 ? <button onClick={handleNextStep}>Siguiente</button> : null
                    }
                </div>
            </div>
        );
    };

    const RenderStepThree = () => {

        ////console.log("Form submitted:", formData);

        const dataUserRsv = {
            identificacion: tipoFactura === 1 ? null : formData.identificacion,
            nombre: formData.nombre,
            apellido: formData.apellido,
            email: formData.email,
            telefono: formData.telefono
        };

        // Save to localStorage
        localStorage.setItem('dataUserRsv', JSON.stringify(dataUserRsv));

        // Log to verify the data
        //console.log('DataUserRsv', dataUserRsv);

        // If you want to verify what's actually stored in localStorage
        //console.log('Stored DataUserRsv:', JSON.parse(localStorage.getItem('dataUserRsv')));


        const handleEmailChange2 = (event) => {
            setEmail2(event.target.value);
        };

        const emailsMatching = formData.email === email2;
        //console.log('emailsMatch:', emailsMatching, "formData.email", formData.email, 'email2', email2)



        //Valid Form

        const isFormValid = () => {

            console.log('MIN CHARS VALIDATE ::::::', minLengthCharsId)

            const commonValidations =
                emailsMatching &&
                email2 !== "" &&
                formData.telefono !== "" &&
                formData.email !== "" &&
                formData.apellido !== "" &&
                formData.nombre !== "";

            if (optionForm === 2) {
                //console.log('Factura');
                if (!minLengthCharsId) {
                    //console.warn('EMPTY')
                    return false
                }
                return commonValidations && idRegularExpression;
            }

            if (optionForm === 1) {
                //console.log('Tiquete');
                return commonValidations;
            }

            return false; // Default return for unexpected cases
        };



        return (
            <div>
                <h2>Datos de la reserva</h2>

                {
                    JSON.parse(localStorage.getItem('rsvSaved')) !== null
                        ? <CheckReserve />
                        :
                        <div>
                            <DisplayReserva
                                date={dataCheckReserva.fecha}
                                nameSede={localStorage.getItem('sede')}
                                hours={localStorage.getItem('horaReservada')}
                                persons={dataCheckReserva.cantidadPersonas} />

                            <TiposDeFactura
                                tipoFactura={tipoFactura}
                                handleTipoFacturaChange={(e) => setTipoFactura(e.target.value)}
                            />

                            <Box
                                sx={boxStyles(theme)}
                            >
                                <StyledTextField
                                    id="nombre"
                                    name="nombre"
                                    value={formData.nombre || ''}
                                    onChange={handleInputChange}
                                    label="Nombre"
                                    variant="filled"
                                    size="small"
                                    required
                                />

                                <StyledTextField
                                    id="apellido"
                                    name="apellido"
                                    value={formData.apellido || ''}
                                    onChange={handleInputChange}
                                    label="Apellido"
                                    variant="filled"
                                    size="small"
                                    required
                                />

                                <StyledTextField
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formData.email || ''}
                                    onChange={handleInputChange}
                                    label="Email"
                                    variant="filled"
                                    size="small"
                                    required
                                />

                                <StyledTextField
                                    id="email2"
                                    name="email2"
                                    type="email"
                                    value={email2}
                                    onChange={handleEmailChange2}
                                    label="Confirmar Email"
                                    variant="filled"
                                    size="small"
                                    error={email2 !== '' && formData.email !== email2}
                                    helperText={email2 !== '' && formData.email !== email2 ? 'Los emails no coinciden' : ''}
                                    required
                                />

                                <CountrySelect
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    getCountryList={getCountryList}
                                    formControlStyles={formControlStyles}
                                />

                                <StyledTextField
                                    id="telefono"
                                    name="telefono"
                                    type="tel"
                                    value={formData.telefono || ''}
                                    onChange={handleInputChange}
                                    variant="filled"
                                    size="small"
                                    label="Teléfono"
                                    required
                                />
                            </Box>


                            <br />

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <button onClick={handlePrevStep}>Atrás</button>
                                {isFormValid() && <button type="submit">Submit</button>}
                            </div>

                            {emailsMatching ? (
                                <p></p>
                            ) : (
                                <Alert severity="warning" style={{ position: 'fixed', top: 0, left: 0, right: 0, justifyContent: 'center' }}>
                                    <AlertTitle>Error Emails</AlertTitle>
                                    Los campos de emails deben ser <strong>Iguales</strong>
                                </Alert>

                            )}

                        </div>
                }
            </div >
        );
    };



    const PreloaderScreen = () => {
        return (
            <div className="preloader">
                <div className="loaderAnimation" >
                    <LoadingScreen />
                    <h3 style={{ color: 'white' }} > Cargando...</h3>
                </div>
            </div >
        )
    }

    return (
        <>

            <HeaderLogoBoliche />
            {
                preloadData === true ? <PreloaderScreen /> : null
            }
            {step === 1 && renderStepOne()}
            {step === 2 && RenderStepTwo()}


            <form onSubmit={handleSubmit}>
                {step === 3 && RenderStepThree()}
            </form>
        </>

    );
};



export default MultiStepForm





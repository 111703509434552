import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CountrySelect = ({ formData, handleInputChange, getCountryList, formControlStyles }) => {
    useEffect(() => {
        if (!formData.pais || formData.pais === 0) {
            const defaultCountry = getCountryList.find(
                country => country.nombre.toLowerCase() === 'costa rica'
            );
            if (defaultCountry) {
                handleInputChange({
                    target: {
                        name: 'pais',
                        value: defaultCountry.idPais
                    }
                });
            }
        }
    }, [getCountryList, formData.pais, handleInputChange]); // Added missing dependencies

    return (
        <FormControl size="small" sx={formControlStyles}>
            <InputLabel id="pais-label">Seleccione país</InputLabel>
            <Select
                labelId="pais-label"
                id="pais"
                name="pais"
                value={formData.pais || 0}
                onChange={handleInputChange}
                label="Seleccione país"
            >
                <MenuItem value={0}>
                    <em>Seleccione país</em>
                </MenuItem>
                {getCountryList.map((country) => (
                    <MenuItem
                        key={country.idPais}
                        value={country.idPais}
                    >
                        {country.descripcion}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CountrySelect;
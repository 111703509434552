import React, { useEffect, useState, useContext } from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    Box,
} from '@mui/material';
import { getTipoFacturaList, getTipoIdentificacionList } from '../api/api-request';
import './styles.css';
import { StyledTextField, formControlStyles } from './StyleToTextField';
import { useTheme } from '@mui/material/styles';
import { setidTipoFactura, setidTipoIdentificacion } from '../helpers/SaveDataFactura';
import { DataContext } from '../../store/DataContext';

const TiposDeFactura = () => {

    const [loading, setLoading] = useState(true);
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [selectedInvoiceType, setSelectedInvoiceType] = useState(null);
    const [identificacionTypes, setIdentificacionTypes] = useState([]);
    const [selectedIdentificationType, setSelectedIdentificationType] = useState(null);
    const [identificacionValue, setIdentificacionValue] = useState('');
    const [error, setError] = useState(null);
    const [isValid, setIsValid] = useState(true);
    const [minLength, setMinLength] = useState(null);
    const [maxLength, setMaxLength] = useState(null);
    const [isNumericOnly, setIsNumericOnly] = useState(false);
    // saveRegularExpression complete save state
    const { setIdRegularExpression, idRegularExpression, setOptionForm, optionForm,
        setMinLengthCharsId } = useContext(DataContext);

    const theme = useTheme();

    const extractLengthConstraints = (pattern) => {
        const exactMatch = pattern.match(/(?:\\d|\[.*?\])\{(\d+)\}/);
        if (exactMatch) {
            const length = parseInt(exactMatch[1]);
            return { min: length, max: length };
        }

        const rangeMatch = pattern.match(/(?:\\d|\[.*?\])\{(\d+),(\d+)\}/);
        if (rangeMatch) {
            return {
                min: parseInt(rangeMatch[1]),
                max: parseInt(rangeMatch[2])
            };
        }

        return { min: null, max: null };
    };

    const isNumericPattern = (pattern) => {
        return pattern.includes('\\d');
    };

    // Initial fetch of invoice types
    useEffect(() => {
        const fetchInvoiceTypes = async () => {
            try {
                const response = await getTipoFacturaList();
                if (response?.statusCode === 200 && response?.data) {
                    setInvoiceTypes(response.data);
                    const tiqueteElectronico = response.data.find(
                        type => type.nombre === 'Tiquete Electrónico' && type.isActive
                    );
                    if (tiqueteElectronico) {
                        setSelectedInvoiceType(tiqueteElectronico.idTipoFactura.toString());
                        setidTipoFactura(tiqueteElectronico.idTipoFactura);
                    }
                } else {
                    setError('Failed to fetch invoice types');
                }
            } catch (error) {
                console.error('Error fetching invoice types:', error);
                setError('Error loading invoice types');
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceTypes();
    }, []);

    // Fetch and handle identification types
    const handleIdentificacionFetch = async (selectedType) => {
        if (selectedType.nombre === 'Factura Electrónica') {
            try {
                const response = await getTipoIdentificacionList();
                if (response?.statusCode === 200 && response?.data) {
                    const processedTypes = response.data.map(type => {
                        const { min, max } = extractLengthConstraints(type.regularExpression);
                        return {
                            ...type,
                            minLength: min,
                            maxLength: max,
                            isNumericOnly: isNumericPattern(type.regularExpression)
                        };
                    });

                    setIdentificacionTypes(processedTypes);

                    // Find Persona Física and set it as default
                    const personaFisica = processedTypes.find(
                        type => type.nombre === 'Persona Física'
                    );

                    if (personaFisica) {
                        setSelectedIdentificationType(personaFisica);
                        setMinLength(personaFisica.minLength);
                        setMaxLength(personaFisica.maxLength);
                        setIsNumericOnly(personaFisica.isNumericOnly);

                        // Save Persona Física ID immediately when found
                        setidTipoIdentificacion(personaFisica.idTipoIdentificacion);
                    }

                    setIdentificacionValue('');
                    setIsValid(true);
                }
            } catch (error) {
                console.error('Error fetching identificacion types:', error);
                setError('Error loading identification types');
            }
        } else {
            // Clear identification related states when not Factura Electrónica
            setIdentificacionTypes([]);
            setSelectedIdentificationType(null);
            setIdentificacionValue('');
            setIsValid(true);
            setMinLength(null);
            setMaxLength(null);
            setIsNumericOnly(false);
            localStorage.removeItem('idTipoIdentificacion');
        }
    };

    const validateIdentification = (value, pattern) => {
        if (!pattern || !value) return true;
        try {
            const cleanPattern = pattern
                .replace(/^\^/, '')
                .replace(/\$$/, '')
                .replace(/\\\\/g, '\\');
            const finalPattern = `^${cleanPattern}$`;
            const regex = new RegExp(finalPattern);
            return regex.test(value);
        } catch (error) {
            console.error('Regex validation error:', error);
            return false;
        }
    };

    // Handle radio button changes for invoice type
    const handleRadioChange = async (event) => {
        const selectedId = event.target.value;
        setSelectedInvoiceType(selectedId);

        const selectedType = invoiceTypes.find(
            type => type.idTipoFactura.toString() === selectedId
        );

        if (selectedType) {
            setidTipoFactura(selectedType.idTipoFactura);
            await handleIdentificacionFetch(selectedType);
        }

        console.log('selectedId value before second if:', selectedId);

        // Convert selectedId to a number for strict comparison
        if (parseInt(selectedId, 10) === 2) {
            setIdRegularExpression(false);
            console.log('HideSubmit');
        }
        console.log('selectType:', selectedId);
        setOptionForm(parseInt(selectedId))
        console.log('optionFOrm:', optionForm);
    };

    // Handle identification type selection changes
    const handleIdentificationTypeChange = (event) => {
        const selectedId = parseInt(event.target.value);
        const selected = identificacionTypes.find(
            type => type.idTipoIdentificacion === selectedId
        );

        if (selected) {
            setSelectedIdentificationType(selected);
            setIdentificacionValue('');
            setIsValid(true);
            setMinLength(selected.minLength);
            setMaxLength(selected.maxLength);
            setIsNumericOnly(selected.isNumericOnly);

            // Ensure we're saving the ID as a number
            setidTipoIdentificacion(selected.idTipoIdentificacion);
        }
    };

    const handleIdentificationValueChange = (event) => {
        const value = event.target.value;

        if (isNumericOnly && !/^\d*$/.test(value)) {
            return;
        }

        if (!isNumericOnly && /['"]/.test(value)) {
            return;
        }

        if (maxLength && value.length > maxLength) {
            return;
        }

        setIdentificacionValue(value)
        localStorage.setItem('identId', value)


        if (selectedIdentificationType?.regularExpression) {
            const isValidInput = validateIdentification(
                value,
                selectedIdentificationType.regularExpression
            );
            setIsValid(isValidInput);
            setIdRegularExpression(isValidInput)
            console.log('isValidInput', isValidInput)


        }
    };

    if (loading) return <div className="text-center p-4">Loading...</div>;
    if (error) return <div className="text-center text-red-500 p-4">{error}</div>;

    setMinLengthCharsId(isValid && identificacionValue !== '' && idRegularExpression)


    return (
        <Box sx={{
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                padding: '3%',
                width: '94%',
            },
            [theme.breakpoints.up('md')]: {
                width: '60%',
            },
        }}>
            <FormControl className="w-full mb-4">
                <RadioGroup
                    value={selectedInvoiceType || ''}
                    onChange={handleRadioChange}
                    row
                    sx={{
                        justifyContent: 'center',
                        '& .MuiFormControlLabel-root': {
                            margin: '0 24px'
                        }
                    }}
                >
                    {invoiceTypes
                        .filter(type => type.isActive)
                        .map(type => (
                            <FormControlLabel
                                key={type.idTipoFactura}
                                value={type.idTipoFactura.toString()}
                                control={<Radio />}
                                label={type.nombre}
                            />
                        ))}
                </RadioGroup>
            </FormControl>

            {selectedInvoiceType && identificacionTypes.length > 0 && (
                <div className="input-section">
                    <FormControl fullWidth size="small" sx={formControlStyles}>
                        <InputLabel>Tipo de Identificación</InputLabel>
                        <Select
                            value={selectedIdentificationType?.idTipoIdentificacion || ''}
                            onChange={handleIdentificationTypeChange}
                            label="Tipo de Identificación"
                        >
                            {identificacionTypes.map((type) => (
                                <MenuItem
                                    key={type.idTipoIdentificacion}
                                    value={type.idTipoIdentificacion}
                                >
                                    {type.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {selectedIdentificationType && (
                        <FormControl fullWidth variant="filled" size="small" sx={{ marginTop: 1, textAlign: 'left' }}>
                            <StyledTextField
                                value={identificacionValue}
                                onChange={handleIdentificationValueChange}
                                error={!isValid && identificacionValue !== ''}
                                placeholder={selectedIdentificationType.mensaje}
                                inputProps={{
                                    maxLength: maxLength,
                                    inputMode: isNumericOnly ? 'numeric' : 'text',
                                    pattern: isNumericOnly ? '[0-9]*' : undefined
                                }}
                                size="small"
                                variant="filled"
                                required
                            />
                            <FormHelperText error={!isValid && identificacionValue !== ''}>
                                {!isValid && identificacionValue !== ''
                                    ? `Formato inválido. ${selectedIdentificationType.mensaje}`
                                    : selectedIdentificationType.mensaje}
                                {' '}
                                <span>
                                    {identificacionValue.length}/
                                    {minLength === maxLength ? maxLength : `${minLength}-${maxLength}`}
                                </span>
                            </FormHelperText>
                        </FormControl>
                    )}
                </div>
            )}
        </Box>
    );
};

export default TiposDeFactura;
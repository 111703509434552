import React from "react";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import MainApp from "./store/MainApp"
import Card from "./pages/CreditCard";
import SuccessReservation from "./pages/SuccessReservation";
import SelectHours from "./components/SelectHours";
import RequestTiloPay from "./components/TiloPayForm";
import ValidateTiloPay from "./components/TiloPayForm/ValidateTiloPay"
import Consultar from "./components/consultar"
import ModifyReserva from "./components/consultar/ModifyReserva";
import DataProvider from "./store/DataProvider";
import Error from "./pages/Error";

export default function App() {

  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/card" element={<Card />} />
          <Route path="/main/:id" element={<MainApp section={':id'} />} />
          <Route path="/success" element={<SuccessReservation />} />
          <Route path="/validate" element={<ValidateTiloPay />} />
          <Route path="*" element={<Error />} />
          <Route path="select" element={<SelectHours />} />
          <Route path="/tilopay" element={<RequestTiloPay />} />
          <Route path="/consultar" element={<Consultar />} />
          <Route path="/modificar" element={<ModifyReserva />} />
        </Routes>
      </Router>

    </DataProvider>


  );
}



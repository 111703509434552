import { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

function SelectHours() {
    const [hours, setHours] = useState([]);
    const [selectedHour, setSelectedHour] = useState('');
    const myHour = []

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch('https://reservasapi.bolichecostarica.com/api/v2/External/GetHoras?IdSede=1', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                        "Accept-Language": "es-MX"
                    }
                });
                const data = await response.json();
                myHour.push(data.data)
                console.log('Hours', myHour[0])
                setHours(myHour[0]);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    const handleHourChange = (event) => {
        setSelectedHour(event.target.value);
    };

    // Render the component
    return (
        <div>
            <FormControl>
                <InputLabel id="hour-select-label">Select Hour</InputLabel>
                <Select
                    labelId="hour-select-label"
                    id="hour-select"
                    value={selectedHour}
                    onChange={handleHourChange}
                >
                    {hours.map((hour) => (
                        <MenuItem key={hour.idHoraDia} value={hour.descripcion}>
                            {hour.descripcion}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {hours.map((hour) => (
                <div key={hour.idHoraDia} value={hour.descripcion}>
                    <p>{hour.idHoraDia}</p>
                    <p>{hour.descripcion}</p>
                </div>
            ))}

        </div>
    );
}

export default SelectHours
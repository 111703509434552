import { createContext } from 'react';

export const DataContext = createContext({
    formDataUser: {},
    updateFormData: () => { },
    dateHour: {},
    updateFormDateHour: () => { },
    data: '',
    setData: () => { },
    identificacionList: [],
    fetchIdentificacionList: async () => { },
    getIdentificacionList: () => [],
    loading: false,
    error: null,
    validateEmails: () => false,
    handleFormSubmit: () => { },
    idRegularExpression: false,
    setIdRegularExpression: () => { },
    optionForm: 1,
    setOptionForm: () => { },
    minLengthCharsId: false,
    setMinLengthCharsId: () => { }
});
import { styled, TextField, FormControl } from "@material-ui/core";

export const StyledTextField = styled(TextField)(() => ({
    '& .MuiFilledInput-root': {
        backgroundColor: 'white',
    },
}));

export const formControlStyles = {
    backgroundColor: 'white',
    marginTop: 2

};


export const alignLeft = {
    textAlign: 'left',
    pl: 1
}
import React, { useEffect, useState, useContext } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleSharp, PaymentsSharp, DescriptionSharp } from '@mui/icons-material';
import './style.css';
import { currentExchangeColon } from '../api/api-request';
import { DataContext } from '../../store/DataContext';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

    },
    containerGetProducts: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: { // For screens small and up
            flexDirection: 'row',
        },
    },
    card: {
        width: 350,
        margin: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'center',
        float: 'left'
    },
}));

const GetProductsRent = () => {
    const { setData } = useContext(DataContext);
    const [selectedButton, setSelectedButton] = useState(null);
    const classes = useStyles();

    const handleButtonClick = (id) => {
        setSelectedButton(id);
        localStorage.setItem('idProductoAlquiler', id);
        setData(id);
    };

    useEffect(() => {
        const savedId = localStorage.getItem('idProductoAlquiler');
        if (savedId) {
            setSelectedButton(parseInt(savedId));
        }
    }, []);

    const renderProductCard = (product) => {
        const { idProductoAlquiler, descripcion, precio } = product;
        const isSelected = selectedButton === idProductoAlquiler;

        return (
            <div
                className="grid-item"
                key={idProductoAlquiler}
            >
                <Card className={classes.card}>
                    <CardContent
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'initial',
                        }}
                        className={isSelected ? 'optionSelect' : 'optionUnselect'}
                    >
                        <Typography variant="h6" component="h3" gutterBottom>
                            <CheckCircleSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                            <span style={{ fontWeight: 'bolder' }}> Seleccione Opción: </span>
                        </Typography>

                        <Typography variant="h6" component="h3" gutterBottom>
                            <PaymentsSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                            {" "}Precio:{" "}
                            <span style={{ fontWeight: 'bolder' }}>
                                ₡{currentExchangeColon(precio)}
                            </span>
                        </Typography>

                        <Typography variant="h6" component="h3" gutterBottom>
                            <DescriptionSharp style={{ fontSize: 22, color: '#1d6a9f' }} />
                            {" "}Descripción:{" "} {descripcion}
                        </Typography>

                        <button
                            className={isSelected ? 'selected' : 'unselected'}
                            onClick={() => handleButtonClick(idProductoAlquiler)}
                        >
                            {isSelected ? 'Seleccionado' : 'Seleccionar'}
                        </button>
                    </CardContent>
                </Card>
            </div>
        );
    };

    const products = JSON.parse(localStorage.getItem('descriptPromoRent'));
    if (!products) return null;

    return (
        <div className={classes.containerGetProducts}>
            {products.map(renderProductCard)}
        </div>
    );
};

export default GetProductsRent;
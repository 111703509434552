import React, { useState } from 'react';
import { DataContext } from './DataContext';
import { getTipoIdentificacionList } from '../components/api/api-request';


const DataProvider = ({ children }) => {
    const [formDataUser, setFormData] = useState({});
    const [dateHour, setDateHour] = useState({});
    const [data, setData] = useState('');
    const [identificacionList, setIdentificacionList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [minLengthCharsId, setMinLengthCharsId] = useState(null);
    //save state regularexpresion fiel identification
    const [idRegularExpression, setIdRegularExpression] = useState(false);
    const [optionForm,
        setOptionForm] = useState(1)

    // Function to update form data
    const updateFormData = (key, value) => {
        setFormData({
            ...formDataUser,
            [key]: value,
        });
    };

    // Function to update date/hour
    const updateFormDateHour = (key, value) => {
        setDateHour({
            ...dateHour,
            [key]: value,
        });
    };

    // Function to fetch and update identification list
    const fetchIdentificacionList = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await getTipoIdentificacionList();

            if (response?.statusCode === 200 && response?.data) {
                setIdentificacionList(response.data);
                // Also store in localStorage as required by the original function
                localStorage.setItem('tipoIdentificacionList', JSON.stringify(response.data));
                return response.data;
            } else {
                const errorMsg = 'No data found in identification list response';
                console.warn(errorMsg);
                setError(errorMsg);
                return null;
            }
        } catch (error) {
            const errorMsg = 'Error fetching identification list';
            console.error(errorMsg, error);
            setError(errorMsg);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Function to get stored identification list
    const getIdentificacionList = () => {
        try {
            const storedList = localStorage.getItem('tipoIdentificacionList');
            return storedList ? JSON.parse(storedList) : [];
        } catch (error) {
            console.error('Error getting identification list from localStorage:', error);
            return [];
        }
    };




    return (
        <DataContext.Provider
            value={{
                data,
                setData,
                formDataUser,
                updateFormData,
                dateHour,
                updateFormDateHour,
                identificacionList,
                fetchIdentificacionList,
                getIdentificacionList,
                loading,
                error,
                setIdRegularExpression,
                idRegularExpression,
                optionForm,
                setOptionForm,
                minLengthCharsId,
                setMinLengthCharsId
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default DataProvider;


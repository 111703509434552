import React, { useEffect, useState } from 'react';
import {
    TextField,
    Typography,
    Divider

} from '@mui/material';
import './style.css';
import { endPointAPiBoleraApp, getHoursBySede, callReserveSaved, setModifyReserve } from '../api/api-request'
import HeaderLogoBoliche from '../header';
import { useNavigate } from 'react-router-dom';
import WinTiloPayPOPUP from './WinTiloPayPOPUP';

const ModifyReserva = () => {
    const [formData, setFormData] = useState({
        primerApellido: '',
        email: '',
        codigoConfirmacion: ''

    });

    const [openPopup, setOpenPopup] = useState(false);
    const [warningMsg, setWarningMsg] = useState(false);
    const [openWarningPopup, setWarningPopup] = useState(false);
    const [getMsgConsult, setMsgConsult] = useState('')
    const [loader, setLoader] = useState(false)
    //== DATA Reserved
    const [getDataRsvd, setDataRsvd] = useState([])
    const [getDataContacto, setDataContacto] = useState([])
    //== Show Reserve Card
    const [getInfoDataRsv, setInfoDataRsv] = useState(false)
    const [getRsvData, setRsvData] = useState([])
    //=Navigate to Validate PAY
    const navigate = useNavigate();
    //data Form
    const [getNombre, setNombre] = useState('');
    const [getFecha, setFecha] = useState('');
    const [getCantidad, setCantidad] = useState('');
    const [getHora, setHora] = useState('');
    const [getIdReservaAlquiler, setIdReservaAlquiler] = useState('');
    const [getIdSede, setIdSede] = useState('');
    const [getCantidadPersonas, setCantidadPersonas] = useState('');
    const [getIdProductoAlquiler, setIdProductoAlquiler] = useState('');
    const [getIdReserva, setIdReserva] = useState('');
    //disable button siguiente
    const [getDisableBtn, setDisableBtn] = useState(true)
    const [getNewFecha, setNewFecha] = useState('');
    const [getNewHour, setNewHour] = useState('');
    const [getNewIdHour, setNewIdHour] = useState('');
    //New Rango de Horas
    const [getNewRangoHorasModificado, setNewRangoHorasModificado] = useState([])


    const handleSubmit = (e) => {
        e.preventDefault();
        handleOpenPopup()
        console.log('CALL UPDATE :::::::: ', getDataRsvd)
        if (!formEmptyStatus) {
            //Open Warning Window
            openWarningPopupWin()
            updateReserva()
        }
    };

    const handleOpenPopup = () => {
        setOpenPopup(true)
        setWarningPopup(false)
        setWarningMsg(true)
        //Loader setup
        setLoader(true)

    }

    const handleClosePopup = () => {
        setOpenPopup(false)
    }

    const openWarningPopupWin = () => {
        setWarningPopup(true)
    }

    //evaluate if form is empty fields newFecha newHour
    const isFormEmpty = (getNewFecha, getNewHour) => {
        if (getNewFecha === '' || getNewHour === '') {
            return true
        } else {
            return false
        }
    };


    let formEmptyStatus = isFormEmpty(getNewFecha, getNewHour);
    console.log('isFormEmpty', formEmptyStatus)

    //Get Data Info Reserve
    const handleReservation = async (consultaReservaModel) => {
        try {
            const { data } = await callReserveSaved(consultaReservaModel);
            if (!data) return;

            const {
                reservaContacto,
                reservaProductoAlquiler,
                idReserva
            } = data;

            const fecha = reservaProductoAlquiler.fecha.split('T')[0];

            // Set both current and new date
            setFecha(fecha);
            setNewFecha(fecha);

            // Update all other states
            setDataRsvd(data);
            setDataContacto(reservaContacto);
            setInfoDataRsv(true);
            setNombre(reservaContacto.nombreCompleto);
            setHora(reservaProductoAlquiler.horaDiaDescripcion);
            setCantidad(reservaProductoAlquiler.cantidadPersonas);
            setIdReservaAlquiler(reservaProductoAlquiler.idReservaProductoAlquiler);
            setIdSede(parseInt(reservaProductoAlquiler.idSede || '0'));
            setIdProductoAlquiler(reservaProductoAlquiler.idProductoAlquiler);
            setCantidadPersonas(reservaProductoAlquiler.cantidadPersonas);
            setIdReserva(idReserva);

            // Fetch hours after setting the date
            await getHoursBySedeBolera();

        } catch (error) {
            console.error('Reservation failed:', error);
        }
    };

    const setModifyReserve = async (data = {}) => {

        const endPointGetReserve = `${endPointAPiBoleraApp}/api/v2/External/ActualizarReserva`

        try {
            const response = await fetch(endPointGetReserve, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "AuthorizationToken": "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e",
                    "Accept-Language": "es-MX"
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Error occurred during the request.');
            }

            const responseData = await response.json();
            const getModifyReserve = responseData.data
            setRsvData(getModifyReserve)
            //if isCorrect is true show reserve
            console.warn("Get Modify RSV", responseData)

            setTimeout(() => { setLoader(false) }, 2500)

        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    const handleModifyReservation = async (reservationData) => {
        try {
            setLoader(true);


            const responseData = await setModifyReserve(reservationData);

            // Handle success (e.g., show success message, redirect)
            console.log('Reservation modified successfully');
            const getModifyReserve = responseData.data
            setRsvData(getModifyReserve)
            //if isCorrect is true show reserve
            console.warn("Get Modify RSV", responseData)
            setTimeout(() => { setLoader(false) }, 2500)

        } catch (error) {
            console.log('Error:', error.message);
            console.error('Failed to modify reservation:', error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const savedData = JSON.parse(localStorage.getItem('consultarReserva'));
                if (savedData) {
                    await handleReservation(savedData);
                    setDisableBtn(isFormEmpty(getNewFecha, getNewHour));
                }
            } catch (error) {
                console.error('Error loading initial data:', error);
            }
        };

        loadInitialData();
    }, []);

    const getHoursBySedeBolera = async () => {
        if (!getNewFecha || !getIdSede) return;

        try {
            const getHours = await getHoursBySede(getNewFecha, getIdSede);
            if (getHours?.data) {
                setNewRangoHorasModificado(getHours.data);
            }
        } catch (error) {
            console.error('Failed to fetch hours:', error);
            setNewRangoHorasModificado([]);
        }
    };

    //componet select hora

    const SelectHora = () => {
        return (
            <select
                id="hour-select"
                name="hora"
                style={{ width: '100%', marginLeft: 10 }}
                onChange={handleHourChange}
                value={getNewHour}
            >
                {

                    getNewRangoHorasModificado.map((hour) => (
                        <option key={hour.idHoraDia} value={hour.descripcion} id={hour.idHoraDia}>
                            {hour.descripcion}
                        </option>
                    ))
                }
            </select>
        )

    }

    const handleHourChange = (event) => {

        const rangoDeHoras = getNewRangoHorasModificado
        const selectedHour = event.target.value;
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
        localStorage.setItem('horaReservada', selectedHour)
        console.log("Selected hour:", selectedHour);
        const filterIdHoraDia = rangoDeHoras.filter(hour => hour.descripcion === event.target.value)
        const getIdHoraDia = filterIdHoraDia[0].idHoraDia
        //setSelectedHour(selectedHour)
        setNewHour(selectedHour)
        localStorage.setItem('idHoraDia', getIdHoraDia)
        setNewIdHour(getIdHoraDia)

    };

    const DateSelect = () => {
        const today = new Date();
        const currentDate = getFecha ? new Date(getFecha) : today;

        return (
            <input
                value={getNewFecha}
                type="date"
                id="fecha"
                name="fecha"
                min={currentDate.toISOString().split('T')[0]}
                onChange={handleInputChange}
            />
        );
    };

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        if (name === 'fecha') {
            const selectedDate = new Date(value);
            const currentDate = getFecha ? new Date(getFecha) : new Date();

            if (selectedDate < currentDate) {
                alert('No puedes seleccionar fechas anteriores a la reserva actual');
                return;
            }

            localStorage.setItem('fecha', value);
            setNewFecha(value);
            await getHoursBySedeBolera();
        }
    };

    const updateReserva = () => {

        if (getDataRsvd !== null) {
            const objectModify = {
                "idReserva": getIdReserva,
                "idReservaProductoAlquiler": getIdReservaAlquiler,
                "idSede": getIdSede,
                "idHoraDia": getNewIdHour,
                "fecha": getNewFecha,
                "cantidadPersonas": getCantidadPersonas,
                "idProductoAlquiler": getIdProductoAlquiler
            }

            console.log('objectModify', objectModify)
            //send endpoint this object to modify reserve
            handleModifyReservation(objectModify)
        }

    }

    //Check if date or hoour are empty
    const checkIfInputsAreEmpty = () => {
        // Check if the inputs are empty
        if (getNewFecha && getNewHour) {
            console.log("One or more fields are empty!");
            setDisableBtn(false)
        } else {
            console.log("All fields are filled!");
        }
    }


    return (
        <div>

            <HeaderLogoBoliche />
            {
                warningMsg
                    ? <WinTiloPayPOPUP
                        message={getMsgConsult}
                        stateValidate={getInfoDataRsv}
                        getDataRsvd={getRsvData}
                        openPopup={openPopup}
                        loader={loader}
                        handleClosePopup={handleClosePopup}
                    />
                    : null
            }

            <div className="form-container" style={{ marginTop: 20 }}>
                <div className="form">
                    <Typography variant='h5'>
                        Modificar reserva
                    </Typography>
                    <TextField
                        name="cliente"
                        label="Cliente"
                        value={getNombre}
                        fullWidth
                        margin="normal"
                        disabled
                        variant="filled"
                    />

                    <TextField
                        name="fecha"
                        label="Fecha"
                        value={getFecha}
                        fullWidth
                        margin="normal"
                        disabled
                        variant="filled"
                    />

                    <TextField
                        name="hora"
                        label="Hora"
                        value={getHora}
                        fullWidth
                        margin="normal"
                        disabled
                        variant="filled"
                    />

                    <TextField
                        name="personas"
                        label="Numero de Personas"
                        value={getCantidad}
                        fullWidth
                        margin="normal"
                        disabled
                        variant="filled"
                    />

                    <Divider>Seleccione la nueva fecha y hora</Divider>
                    <br />

                    <div className="input-field">
                        <DateSelect />
                    </div>

                    <div className="input-field" name="hora">
                        {getNewRangoHorasModificado !== null ? <SelectHora /> : null}
                    </div>


                    <button
                        variant="contained"
                        className="btnSend"
                        onClick={handleSubmit}
                        disabled={formEmptyStatus}
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div>

    );
};

export default ModifyReserva;
import React, { useEffect, useState } from "react";
const SuccessReservation = ({ codeConfirm }) => {

    const [fecha, setFecha] = useState('')
    const [hora, setHora] = useState('')
    const [code, setCodigo] = useState('')

    useEffect(() => {
        setFecha(localStorage.getItem('fecha'))
        setHora(localStorage.getItem('horaReservada'))
        setCodigo(localStorage.getItem('rsvCode'))
    }, [

    ])

    return (
        <div>
            <h3>Reserva Generada Exitosamente</h3>
            <h4>Fecha:{' '}{fecha}</h4>
            <h4>Hora:{' '}{hora}</h4>
            <h4>Se le enviará un correo electrónico con la confirmación de su reserva.</h4>
        </div>
    )
}

export default SuccessReservation
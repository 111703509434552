
export const boxStyles = (theme) => ({
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    '& .MuiTextField-root': {
        width: '90%'
    },
    '& .MuiFormControl-root': {
        width: '100%'
    },
    marginTop: -4,
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
        padding: '3%',
        width: '94%',
    },
    [theme.breakpoints.up('md')]: {
        width: '60%',
    },
});

export const alternativeBoxStyles = (theme) => ({
    ...boxStyles(theme),
    // Add any variations here
    padding: 3,
    marginTop: 0,
});
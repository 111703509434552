import React from "react";
import HeaderLogoBoliche from "../components/header";
import { Typography } from "@mui/material";
import { Link } from 'react-router-dom'
import BolSad from '../images/pine-sad-error.png'
import { Card } from "@mui/material";


const Error = () => {
    return (
        <div>
            <HeaderLogoBoliche />
            <div style={{
                width: "100%",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: 35
            }}>

                <Typography variant="h5" align="center" >
                    Pagina no encontrada volver al <Link to="/">inicio</Link>
                </Typography>
                <Card style={{ marginTop: 20 }}>
                    <img
                        width="200"
                        height="300"
                        alt="pagina no encontrada"
                        style={{ padding: 10, marginTop: 15 }}
                        src={BolSad}
                        sizes="(max-width: 300px) 100vw, 300px" />
                </Card>


            </div>
        </div>
    )
}

export default Error
import React, { useState } from 'react';
import { Typography, CircularProgress, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import HeaderLogoBoliche from '../header';
import SuccessReservation from '../../pages/SuccessReservation';
import { apiV2, endPointAPiBoleraApp, headers } from '../api/api-request';

const TiloPayForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [validationStatus, setValidationStatus] = useState({
        attempted: false,
        isSuccess: false,
        error: null
    });
    const [reservationData, setReservationData] = useState(null);
    const navigate = useNavigate();

    const backToForm = () => navigate('/tilopay');
    const goToConsulta = () => navigate('/consultar');

    const getReservationDetails = async (reserveId) => {
        try {
            debugger;
            const response = await fetch(
                `${endPointAPiBoleraApp}${apiV2}/GetDetailsById/${reserveId}`,
                { method: 'GET', headers }
            );

            if (!response.ok) {
                throw new Error(`Failed to fetch reservation details: ${response.status}`);
            }

            const data = await response.json();

            if (!data?.data?.reservaConfirmacion) {
                throw new Error('Invalid reservation data structure');
            }

            setReservationData(data.data);
            localStorage.setItem('rsvCode', data.data.reservaConfirmacion.codigoConfirmacion);
            localStorage.setItem('rsvComplete', JSON.stringify(data));

            return data;
        } catch (error) {
            console.error('Error fetching reservation details:', error);
            throw error;
        }
    };

    const validatePayment = async () => {
        const reserveId = localStorage.getItem('idRsv');

        if (!reserveId) {
            setValidationStatus({
                attempted: true,
                isSuccess: false,
                error: 'No reservation ID found'
            });
            return;
        }

        setIsLoading(true);

        try {
            const validateResponse = await fetch(
                `${endPointAPiBoleraApp}${apiV2}/ValidarPagoReserva/${reserveId}`,
                { method: 'GET', headers }
            );

            if (!validateResponse.ok) {
                throw new Error('Payment validation failed');
            }

            const validateData = await validateResponse.json();

            if (!validateData.isCorrect || !validateData.data) {
                throw new Error(validateData.message);
            }

            //const reservationDetails = await getReservationDetails(reserveId);
            const reservationDetails = localStorage.getItem('rsvSaved');
            setReservationData(reservationDetails);

            setValidationStatus({
                attempted: true,
                isSuccess: true,
                error: null
            });
        } catch (error) {
            console.error('Validation error:', error);
            setValidationStatus({
                attempted: true,
                isSuccess: false,
                error: error.message
            });
        } finally {
            setIsLoading(false);
        }
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                    <Typography>Validando pago...</Typography>
                </div>
            );
        }

        if (!validationStatus.attempted) {
            return (
                <>
                    <Typography style={{ textAlign: 'center', marginBottom: '1rem' }}>
                        Luego de realizar el pago con el proveedor de pagos,
                        por favor valide la transacción presionando el botón de Validar Pago.
                        Puede intentar validar el pago las veces que lo necesite.
                    </Typography>
                    <Typography style={{ textAlign: 'center', marginBottom: '1rem' }}>
                        Si desea corregir o modificar el pago por favor haga click
                        en Regresar y llene el formulario de nuevo
                    </Typography>
                </>
            );
        }

        if (validationStatus.isSuccess) {
            return (
                <>
                    <SuccessReservation
                        
                    />
                    <Alert severity="success">
                        Su pago fue exitoso! — Gracias!
                    </Alert>
                </>
            );
        }

        return (
            <Alert severity="error">
                {validationStatus.error || 'Error en el pago, favor revisar!'}
            </Alert>
        );
    };

    const renderButtons = () => {
        const buttonStyle = {
            backgroundColor: '#4a148c',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            width: '45%',
            fontSize: '1rem'
        };

        if (validationStatus.isSuccess && reservationData?.reservaConfirmacion?.codigoConfirmacion) {
            return (
                <button onClick={goToConsulta} style={buttonStyle}>
                    <HomeIcon /> Consultar su reserva
                </button>
            );
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '2rem' }}>
                <button onClick={backToForm} style={buttonStyle}>
                    Regresar
                </button>
                <button onClick={validatePayment} style={buttonStyle}>
                    Validar Pago
                </button>
            </div>
        );
    };

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <HeaderLogoBoliche />
            <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px'
            }}>
                <div style={{
                    width: '90%',
                    maxWidth: '500px',
                    backgroundColor: 'white',
                    padding: '2rem',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    <Typography
                        variant="h5"
                        style={{
                            textAlign: 'center',
                            marginBottom: '2rem',
                            color: '#333'
                        }}
                    >
                        Validación del Pago
                    </Typography>

                    {renderContent()}
                    {renderButtons()}
                </div>
            </div>
        </div>
    );
};

export default TiloPayForm;

//export const BASE_URL = "http://192.168.1.109:64388";
export const BASE_URL = "https://reservasapi.bolichecostarica.com";
export const apiV2 = "/api/v2/External";
export const AUTH_TOKEN = "e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e";

export const headers = {
    "Content-Type": "application/json",
    "AuthorizationToken": AUTH_TOKEN,
    "Accept-Language": "es-MX"
};

export const endPointAPiBoleraApp = BASE_URL


export async function getSedeId(sedeID) {
    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GetSede/${sedeID}`, {
            method: "GET",
            headers
        });
        const data = await response.json();
        // console.log('GetSedeOBJ', data);
        // console.log('idSede', data.data.idSede, "Nombre:", data.data.nombre);
        localStorage.setItem('sede', data.data.nombre);
        return data
    } catch (error) {
        console.error(error);
    }
}

//get list country
export const getListCountry = async () => {
    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GetPaisList`, {
            method: 'GET',
            headers,
        });

        if (!response.ok) {
            throw new Error('Error occurred during the request.');
        }

        const responseData = await response.json();
        //console.log('Response get Country', responseData);
        return responseData


    } catch (error) {
        console.error('Error:', error.message);
        return []
    }
}

export const getHoursBySede = async (fecha, sede) => {
    const constDataObject = {
        fecha: fecha,
        idSede: sede
    }

    //console.log('GetHoursOBJ', constDataObject, "Fecha", constDataObject.idSede)

    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GetHorasPorSede`, {
            method: "POST",
            headers,
            body: JSON.stringify(constDataObject)
        })

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        //console.log('dataGetHorasAPI: ', data.data)
        if (!data.isCorrect) {
            alert(data.message)
        }

        return data


    } catch (error) {
        console.error('Error:', error.message);
        return []
    }
}


// Get Alquiler Productos
export const getDataProducts = async () => {
    // Retrieve data from localStorage
    const rentProducts = {
        idSede: localStorage.getItem('sedeID'),
        idHoraDia: localStorage.getItem('idHoraDia'), // hourDay
        fecha: localStorage.getItem('fecha'),
        cantidadPersonas: localStorage.getItem('cantidad')
    };

    // Validate rentProducts object to ensure all required fields are present
    if (!rentProducts.idSede || !rentProducts.idHoraDia || !rentProducts.fecha || !rentProducts.cantidadPersonas) {
        console.warn('Missing required fields in rentProducts:', rentProducts);
        return { message: 'Missing required fields for fetching products', isCorrect: false };
    }

    console.log('PAYLOAD OBJ', rentProducts);

    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GetProductosAlquiler`, {
            method: "POST",
            headers,
            body: JSON.stringify(rentProducts)
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        const data = await response.json();

        // Store necessary data in localStorage
        localStorage.setItem('descriptPromoRent', JSON.stringify(data.data));
        localStorage.setItem('isCorrect', JSON.stringify(data.isCorrect));
        localStorage.setItem('msgFromAPi', JSON.stringify(data.message));

        console.log('DATA RESERVED', data);
        return data;

    } catch (error) {
        console.error('Error fetching products:', error);
        localStorage.setItem('msgFromAPi', JSON.stringify({ error: error.message }));
        return { message: error.message, isCorrect: false };
    }
};


//==== SAVE RESERVE =============================================

export const saveReserveData = async () => {

    const dataReserva = JSON.parse(localStorage.getItem('dataUserRsv'))

    const idTipoIdentificacion = localStorage.getItem('idTipoIdentificacion');
    const parsedIdTipoIdentificacion = idTipoIdentificacion && idTipoIdentificacion.trim() !== ''
        ? parseInt(idTipoIdentificacion, 10)
        : null;

    const objectForSaveReserve = {
        "idReserva": 0,
        "idReservaProductoAlquiler": 0,
        "idSede": localStorage.getItem('sedeID'),
        "idHoraDia": localStorage.getItem('idHoraDia'),
        "fecha": localStorage.getItem('fecha'),
        "cantidadPersonas": localStorage.getItem('cantidad'),
        "idProductoAlquiler": parseInt(localStorage.getItem('idProductoAlquiler')),
        "idReservaContacto": 0,
        "nombre": dataReserva.nombre,
        "primerApellido": dataReserva.apellido,
        "email": dataReserva.email,
        "telefono": dataReserva.telefono,
        "identificacion": localStorage.getItem('identId'),
        "idTipoIdentificacion": parsedIdTipoIdentificacion,
        "idTipoFactura": parseInt(localStorage.getItem('idTipoFactura'))
    };

    console.log('{ OBJ Save Reserved }:', objectForSaveReserve)

    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GuardarReserva`, {
            method: "POST",
            headers,
            body: JSON.stringify(objectForSaveReserve)
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        //console.log('Reserve saved: ->', data)
        //console.log('rsvSaved: ', JSON.stringify(data.data))
        localStorage.setItem('rsvSaved', JSON.stringify(data.data))
        //sub total
        localStorage.setItem('subTotal', JSON.stringify(data.data.subTotal))
        //Comissions Payed
        localStorage.setItem('totalComisiones', JSON.stringify(data.data.totalComisiones))
        localStorage.setItem('totalImpuestos', JSON.stringify(data.data.totalImpuestos))
        localStorage.setItem('totalSinImpuestos', JSON.stringify(data.data.totalSinImpuestos))
        //set Total
        localStorage.setItem('rsvTotal', JSON.stringify(data.data.total))

        //set Descript
        localStorage.setItem('rsvDescript', JSON.stringify(data.data.reservaProductoAlquiler.descripcion))
        //idRerserva
        localStorage.setItem('idRsv', JSON.stringify(data.data.idReserva))

    } catch (error) {
        console.log('Error', error)
    }
};

export const currentExchangeColon = (amount) => {
    const formattedAmount = amount.toLocaleString();
    return formattedAmount
}


// PROCESAR PAGO
export async function ProcesarPago() {
    try {
        // Parse the JSON string from localStorage
        const dataUserReserv = JSON.parse(localStorage.getItem('dataUserRsv'));

        // Validate if we have the required data
        if (!dataUserReserv || !dataUserReserv.apellido || !dataUserReserv.email) {
            throw new Error('Missing required user data for payment processing');
        }

        const reservObject = {
            "idReserva": localStorage.getItem('idRsv'),
            "primerApellido": dataUserReserv.apellido,
            "email": dataUserReserv.email
        };

        const response = await fetch(`${BASE_URL}${apiV2}/ProcesarPago`, {
            method: "POST",
            headers,
            body: JSON.stringify(reservObject) // Make sure to stringify the body
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log('Payment Process:', {
        //     result: data,
        //     reservObject,
        //     userData: dataUserReserv
        // });

        return data;

    } catch (error) {
        console.error('Payment processing error:', error);
        throw error; // Re-throw to handle in calling code
    }
}

//Consultar Reserva

export const getConsultReserve = async (
    data = {},
    setMsgConsult,
    setStateConsult,
    setLoader,
    callReserveSaved
) => {
    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GetDetailsById`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'AuthorizationToken': 'e80d2ed4-7dcc-4d6f-bf57-3f0eb0b5dc5e',
                'Accept-Language': 'es-MX'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Error occurred during the request.');
        }

        const responseData = await response.json();

        // Update message and state
        setMsgConsult(responseData.message);
        setStateConsult(responseData.isCorrect);

        const getIdReserve = responseData.data;
        console.log('This is RESERV3: ', getIdReserve);

        // Handle successful reserve
        if (responseData.isCorrect) {
            callReserveSaved(getIdReserve);
        }

        // Update loader state after delay
        setTimeout(() => {
            setLoader(false);
        }, 2500);

        return responseData;
    } catch (error) {
        console.error('Error:', error.message);
        setLoader(false);
        throw error;
    }
};

// Optional: Add more related functions
export const validateReserveData = (data) => {
    // Add validation logic here
    return true;
};

// Optional: Export constants
export const RESERVE_STATUS = {
    PENDING: 'pending',
    CONFIRMED: 'confirmed',
    FAILED: 'failed'
};

//tipo de factura
export async function getTipoFacturaList() {
    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GetTipoFacturaList`, {
            method: "GET",
            headers
        });

        const data = await response.json();
        console.log('GetTipoFacturaList Response:', data);

        // Example of storing the list in localStorage
        if (data && data.data) {
            localStorage.setItem('tipoFacturaList', JSON.stringify(data.data));
        } else {
            console.warn('No data found in response:', data);
        }

        return data;
    } catch (error) {
        console.error('Error fetching Tipo Factura List:', error);
    }
}
//Tipo de Identificacion
export async function getTipoIdentificacionList() {
    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GetTipoIdentificacionList`, {
            method: "GET",
            headers
        });

        const data = await response.json();
        console.log('GetTipoIdentificacionList Response:', data);

        // Example of storing the list in localStorage
        if (data && data.data) {
            localStorage.setItem('tipoIdentificacionList', JSON.stringify(data.data));
        } else {
            console.warn('No data found in response:', data);
        }

        return data;
    } catch (error) {
        console.error('Error fetching Tipo Identificacion List:', error);
    }
}

//Modify Reserve
export const callReserveSaved = async (consultaReservaModel) => {
    if (!consultaReservaModel) {
        throw new Error('consultaReservaModel is required');
    }

    try {
        const response = await fetch(`${BASE_URL}${apiV2}/GetDetailsByConfirmacion`, {
            method: 'POST',
            headers,
            body: JSON.stringify(consultaReservaModel)
        });

        if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
        }

        const result = await response.json();
        if (!result.data) {
            throw new Error('No data received from API');
        }

        return result; // Return the data

    } catch (error) {
        console.error('Failed to fetch reservation details:', error.message);
        throw error;
    }
};

//Set Update Reserved
export const setModifyReserve = async (data = {}) => {

    const endPointGetReserve = `${endPointAPiBoleraApp}/api/v2/External/ActualizarReserva`

    try {
        const response = await fetch(endPointGetReserve, {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Error occurred during the request.');
        }

        const responseData = await response.json();
        //if isCorrect is true show reserve
        console.warn("Get Modify RSV", responseData)



    } catch (error) {
        console.error('Error:', error.message);
    }
}

import React, { useEffect, useState, useContext } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    CircularProgress,
} from '@mui/material';
import './style.css';
import { ProcesarPago } from '../api/api-request';
import HeaderLogoBoliche from '../header';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../store/DataContext';

const msgPopupWarning = `Se abrirá una ventana con el provedor de pagos, 
                        luego de realizar el pago cierre la ventana emergente, 
                        y compruebe que el pago haya sido registrado, 
                        posterior a eso puede confirmar su reserva`;

const TiloPayForm = () => {
    const [paymentState, setPaymentState] = useState({
        url: '',
        loading: true,
        error: null,
        showPopup: false
    });

    const navigate = useNavigate();
    const { formDataUser } = useContext(DataContext);

    const handleClosePopup = () => {
        setPaymentState(prev => ({ ...prev, showPopup: false }));
        setTimeout(() => {
            navigate('/validate');
        }, 500);
    };

    // Process Payment and Get URL
    useEffect(() => {
        const processPayment = async () => {
            try {
                const result = await ProcesarPago();
                if (result?.data) {
                    setPaymentState({
                        url: result.data,
                        loading: false,
                        error: null,
                        showPopup: true
                    });
                } else {
                    throw new Error('No payment URL received');
                }
            } catch (error) {
                console.error('Payment processing error:', error);
                setPaymentState({
                    url: '',
                    loading: false,
                    error: error.message || 'Failed to process payment',
                    showPopup: false
                });
            }
        };

        processPayment();
    }, []);

    // Payment Window Component
    const PaymentWindow = () => (
        <Dialog
            open={paymentState.showPopup}
            maxWidth="md"
            fullWidth
            onClose={handleClosePopup}
        >
            {paymentState.loading ? (
                <div style={{
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <CircularProgress style={{ color: 'purple' }} />
                    <Typography>
                        Cargando...
                    </Typography>
                </div>
            ) : (
                <>
                    <DialogContent>
                        <iframe
                            src={paymentState.url}
                            title="TiloPay"
                            width="100%"
                            height="550px"
                            frameBorder={0}
                        />
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleClosePopup} className="btnSend">
                            Cerrar {'×'}
                        </button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );

    // Initial Warning Dialog
    const WarningDialog = () => (
        <Dialog
            open={!paymentState.showPopup && !paymentState.error}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <Typography>
                    {msgPopupWarning}
                </Typography>
            </DialogContent>
        </Dialog>
    );

    // Error Dialog
    const ErrorDialog = () => (
        <Dialog
            open={!!paymentState.error}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <Typography color="error">
                    {paymentState.error}
                </Typography>
            </DialogContent>
            <DialogActions>
                <button onClick={() => navigate(-1)} className="btnSend">
                    Volver
                </button>
            </DialogActions>
        </Dialog>
    );

    return (
        <div>
            <HeaderLogoBoliche />
            {paymentState.error ? <ErrorDialog /> : null}
            {paymentState.showPopup ? <PaymentWindow /> : <WarningDialog />}
        </div>
    );
};

export default TiloPayForm;